<template lang="pug">
  include ../../assets/pug/index
  .test-content
    .test-content__card
      v-card.mb-4(outlined)
        v-card-title.mb-6 4. {{ $t('protocol.title4') }}.
        v-card-text
          v-row
            v-col(md="6" cols="12")
              +inputValidation('car_parameters.numberChassis', 'protocol.vinLabel', '["required"]')

            v-col(md="6" cols="12")
              +autocomplete('car_parameters.category', 'stand.standData.categories', 'name', 'uid', 'protocol.carCategoryLabel', '["required"]')

            v-col(md="6" cols="12")
              +autocomplete('car_parameters.manufacturer', 'stand.standData.manufacturers', 'name', 'uid', 'protocol.manufacturer', '["required"]')(@input="getModel(car_parameters.manufacturer)")

            v-col(md="6" cols="12")
              +autocomplete('car_parameters.model', 'stand.model', 'name', 'uid', 'protocol.model', '["required"]')

            v-col(md="6" cols="12")
              +inputValidation('car_parameters.car_license_plate', 'registrationNumber', '["required", "length", "LICENSE_PLATE_REGEX"]')(@blur="licensePlateUpper" maxLength="8")

            v-col(md="6" cols="12")
              +inputValidation('car_parameters.odometr', 'odometer', '["required", "VERSION_REGEX"]')(:suffix="$t('unitKilometer')")

            v-col(md="6" cols="12")
              +autocomplete('car_parameters.fuel_type_id', 'stand.standData.fuel_types', 'name', 'uid', 'fuelType', '["required"]')

            v-col(md="6" cols="12")
              +autocomplete('car_parameters.turbine_compressor', 'turbineCompressorArray', 'name', 'id', 'protocol.turbineCompressorLabel', '["required"]')

            v-col(md="6" cols="12")
              +datePicker('isShowManufactureDate', 'car_parameters.manufacture_date', 'protocol.manufactureDate')

            v-col(md="6" cols="12")
              +datePicker('isShowStateRegistrationDate', 'car_parameters.state_registration_date', 'protocol.stateRegistrationDate')

            v-col.py-0(md="6" cols="12")
              .label-block(style="margin-top: 1px") {{ $t('protocol.dateNumberDocRe-equipmentLabel') }}
              v-row.mt-n6
                v-col(md="6" cols="12")
                  +datePicker('isShowDocsReequipmentDate', 'car_parameters.reequipment_doc_date', '')(:placeholder="$t('protocol.dateDocRe-equipment')")
                v-col(md="6" cols="12")
                  +inputValidation('car_parameters.reequipment_doc_number', '', '["required"]')(:placeholder="$t('protocol.numberDocRe-equipment')")

            v-col(md="6" cols="12")
              +inputValidation('car_parameters.essenceReequipment', 'protocol.essenceRe-equipmentLabel', '["required"]')

            v-col(md="4" cols="12")
              +inputValidation('car_parameters.numberCylinders', 'protocol.numberCylindersLabel', '["required", "VERSION_REGEX"]')(type="number")

            v-col(md="4" cols="12")
              +inputValidation('car_parameters.ecologicalLeve', 'protocol.ecologicalLevelLabel', '["required"]')

            v-col(md="4" cols="12")
              +autocomplete('car_parameters.color', 'stand.standData.colors', 'name', 'uid', 'protocol.colorLabel', '["required"]')

            v-col.pt-0(cols="6")
              .label-block(style="margin-top: 1px") {{ $t('protocol.purposeLabel') }}
              v-row.mt-n6
                v-col(md="6" cols="12")
                  v-radio-group.default-input(v-model="car_parameters.isTaxi" :label="$t('protocol.taxi')")
                    v-radio(:label="$t('protocol.yes')" :value="true")
                    v-radio(:label="$t('protocol.no')" :value="false")
                    v-radio(:label="$t('protocol.notSpecified')" :value="null")

                v-col(md="6" cols="12")
                  v-radio-group.default-input(v-model="car_parameters.isDangerousGoods" :label="$t('protocol.dangerousGoods')")
                    v-radio(:label="$t('protocol.yes')" :value="true")
                    v-radio(:label="$t('protocol.no')" :value="false")
                    v-radio(:label="$t('protocol.notSpecified')" :value="null")

            v-col(md="6" cols="12")
              v-row
                v-col(md="4" cols="12")
                  +inputValidation('car_parameters.air_temperature', 'protocol.airTemperature', '["required"]')
                v-col(md="4" cols="12")
                  +inputValidation('car_parameters.humidity', 'protocol.humidity', '["required"]')
                v-col(md="4" cols="12")
                  +inputValidation('car_parameters.atmospheric_pressure', 'protocol.atmosphericPressure', '["required"]')

      v-card.mb-4(outlined)
        v-card-title {{ $t('protocol.testResults') }}

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.generalCharacteristicsTitle') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')

            tbody
              +tableRowRadioInput('protocol.passengerCapacityItem', 'test_results.passenger_capacity', 'test_results.passenger_capacity_code')
              +tableRowRadioInput('protocol.conditionConstructionItem', 'test_results.condition_construction', 'test_results.condition_construction_code')
              +tableRowRadioInput('protocol.seatBeltsItem', 'test_results.seat_belts', 'test_results.seat_belts_code')
              +tableRowRadioInput('protocol.cabinLockItem', 'test_results.cabin_lock', 'test_results.cabin_lock_code')
              +tableRowRadioInput('protocol.antiGlareDeviceItem', 'test_results.antiglare_device', 'test_results.antiglare_device_code')
              +tableRowRadioInput('protocol.ejectionDevicesItem', 'test_results.ejection_devices', 'test_results.ejection_devices_code')
              +tableRowRadioInput('protocol.bumperItem', 'test_results.bumper', 'test_results.bumper_code')
              +tableRowRadioInput('protocol.reEquipmentItem', 'test_results.reEquipment', 'test_results.reEquipment_code')

        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.pneumaticTiresTitle') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')

            tbody
              +tableRowRadioInput('protocol.numberAndConditionPneumaticTiresItem', 'pneumatic_tires.number_condition', 'pneumatic_tires.number_condition_code', 'hideNotSpecified')
              +tableRowRadioInput('protocol.technicalConditionPneumaticTiresItem', 'pneumatic_tires.technical_condition', 'pneumatic_tires.technical_condition_code', 'hideNotSpecified')

        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.tirePatternHeight') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.vehicleCategory')
                +tableHeadSimple('protocol.notLessThan')
                +tableHeadSimple('protocol.measuredValueOrIndicatorCompliance')

            tbody
              tr
                td  {{ $t('protocol.vehicleCategoryItem1') }}
                td  1,6
                td
                  +input('tire_tread.tire_tread_one', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
              tr
                td  {{ $t('protocol.vehicleCategoryItem2') }}
                td  2,0
                td
                  +input('tire_tread.tire_tread_two', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
              tr
                td  {{ $t('protocol.vehicleCategoryItem3') }}
                td  1,0
                td
                  +input('tire_tread.tire_tread_three', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1


        v-divider
        .caption.grey--text.py-1.px-4 {{ $t('protocol.measuredValueOrIndicatorComplianceTip') }}
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.tirePressure') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            tbody
              tr
                td {{ $t('protocol.valueTirePressure') }}, {{ $t('unitPressure') }}
                td
                  .table-label 1 {{ $t('shaft') }}
                  +input('tire_pressure.tire_pressure_shaft_one', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  .table-label 2 {{ $t('shaft') }}
                  +input('tire_pressure.tire_pressure_shaft_one', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  .table-label 3 {{ $t('shaft') }}
                  +input('tire_pressure.tire_pressure_shaft_one', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  .table-label 4 {{ $t('shaft') }}
                  +input('tire_pressure.tire_pressure_shaft_one', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1

        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.wheels') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')

            tbody
              +tableRowRadioInput('protocol.numberAndConditionWheels', 'wheels.number_and_condition_wheels', 'wheels.number_and_condition_wheels_code', 'hideNotSpecified')
              +tableRowRadioInput('protocol.technicalCondition', 'wheels.technical_condition', 'wheels.technical_condition_code', 'hideNotSpecified', '5.5.2')
              +tableRowRadioInput('protocol.tighteningWheelBolts', 'wheels.tightening_wheel_bolts', 'wheels.tightening_wheel_bolts_code', 'hideNotSpecified')

        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.protectiveDevices') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')

            tbody
              +tableRowRadioInput('protocol.rearProtectiveDevice', 'wheels.rear_protective_devices', 'wheels.rear_protective_devices_code')
              +tableRowRadioInput('protocol.sideProtectionDevices', 'wheels.side_protective_devices', 'wheels.side_protective_devices_code')

        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.glass') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')

            tbody
              +tableRowRadioInput('protocol.glassRequirementsConstruction', 'glass.glass_requirements_construction', 'glass.glass_requirements_construction_code')
              +tableRowRadioInput('protocol.technicalCondition', 'glass.glass_technical_condition', 'glass.glass_technical_condition_code', '', '5.7.2')

        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.parametersOrRequirements')
                +tableHeadSimple('protocol.standardNotLessThan')
                +tableHeadSimple('protocol.measurementResults')
                +tableHeadSimple('protocol.conformity')

            tbody
              tr
                td {{ $t('protocol.lightTransmissionWindshield') }}
                td 75
                td
                  +input('glass.light_transmission_windshield_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  +radioGroup('glass.light_transmission_windshield')
              tr
                td {{ $t('protocol.lightTransmissionFrontLeftGlass') }}
                td 70
                td
                  +input('glass.light_transmission_front_left_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  +radioGroup('glass.light_transmission_front_left')
              tr
                td {{ $t('protocol.lightTransmissionFrontRightGlass') }}
                td 70
                td
                  +input('glass.light_transmission_front_right_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  +radioGroup('glass.light_transmission_front_right')
              tr
                td {{ $t('protocol.lightTransmissionBackRightGlass') }}
                td 70
                td
                  +input('glass.light_transmission_back_right_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  +radioGroup('glass.light_transmission_back_right')
              tr
                td {{ $t('protocol.lightTransmissionBackLeftGlass') }}
                td 70
                td
                  +input('glass.light_transmission_back_left_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  +radioGroup('glass.light_transmission_back_left')
              tr
                td {{ $t('protocol.lightTransmissionRear') }}
                td 70
                td
                  +input('glass.light_transmission_rear_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  +radioGroup('glass.light_transmission_rear')
              tr
                td {{ $t('protocol.mismatchCode') }}
                td(colspan=3)
                  +input('glass.glass_light_transmission_code', '')(:placeholder="$t('protocol.mismatchCode')" hide-details).mt-0.pa-1
        v-divider
        .caption.grey--text.pt-2.px-4 {{ $t('protocol.glassNoteOne') }}
        .caption.grey--text.pb-1.px-4 {{ $t('protocol.glassNoteTwo') }}
        v-divider

        v-card-subtitle.title.mt-4.pb-0.font-weight-bold {{ $t('protocol.lightingDevices') }}
        v-card-subtitle.pt-2.font-weight-bold {{ $t('protocol.highAndLowBeamsFogLights') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.requirements')(rowspan=3).text-center
                +tableHeadSimple('protocol.parametersBeingTested')(colspan=3).text-center
              tr
                +tableHeadSimple('protocol.highBeamHeadlights').text-center
                +tableHeadSimple('protocol.dippedBeamHeadlights').text-center
                +tableHeadSimple('protocol.frontFogLamp').text-center

              tr
                +tableHeadSimple('protocol.conformity')(colspan=3).text-center

            tbody
              tr
                td {{ $t('protocol.availabilityConditionStructureFunctionality') }}
                td
                  +radioGroup('lighting.high_beam_headlights_availability')
                td
                  +radioGroup('lighting.low_beam_headlights_availability')
                td
                  +radioGroup('lighting.front_fog_lamp_availability')
              tr
                td {{ $t('protocol.technicalCondition') }}
                td
                  +radioGroup('lighting.high_beam_headlights_technical')
                td
                  +radioGroup('lighting.low_beam_headlights_technical')
                td
                  +radioGroup('lighting.front_fog_lamp_technical')
              tr
                td {{ $t('protocol.adjustDirectionRayPropagation') }}
                td
                  +radioGroup('lighting.high_beam_headlights_direction')
                td
                  +radioGroup('lighting.low_beam_headlights_direction')
                td
                  +radioGroup('lighting.front_fog_lamp_direction')
              tr
                td {{ $t('protocol.lightDistribution') }}
                td
                  +radioGroup('lighting.high_beam_headlights_distribution')
                td
                  +radioGroup('lighting.low_beam_headlights_distribution')
                td
                  +radioGroup('lighting.front_fog_lamp_distribution')

            thead
              tr
                +tableHeadSimple('protocol.requirements').text-center
                +tableHeadSimple('protocol.standard').text-center
                +tableHeadSimple('result')(colspan=2).text-center
            tbody
              tr
                td {{ $t('protocol.powerLight') }}, {{ $t('unitPowerLight') }}
                td 20000 – 225000 ({{ $t('protocol.highBeam') }})*
                td(colspan="2")
                  +input('lighting.light_power_high_beam', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
              tr
                td {{ $t('protocol.powerLight') }}, {{ $t('unitPowerLight') }}
                td ≤625/≤625 ({{ $t('protocol.frontFogLampTO') }})
                td
                  .table-label {{ $t('protocol.left') }}
                  +input('lighting.light_power_front_fog_lamp_left', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  .table-label {{ $t('protocol.right') }}
                  +input('lighting.light_power_front_fog_lamp_right', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
              tr
                td {{ $t('protocol.powerLight') }}, {{ $t('unitPowerLight') }}
                td ≥2200 ({{ $t('protocol.lowBeamInAreaIntenseLight') }})
                td
                  .table-label {{ $t('protocol.left') }}
                  +input('lighting.light_power_intense_lighting_left', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  .table-label {{ $t('protocol.right') }}
                  +input('lighting.light_power_intense_lighting_right', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
              tr
                td {{ $t('protocol.powerLight') }}, {{ $t('unitPowerLight') }}
                td ≤950/≤950** ({{ $t('protocol.lowBeamInLowLightArea') }})
                td
                  .table-label {{ $t('protocol.left') }}
                  +input('lighting.light_power_low_light_left', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td
                  .table-label {{ $t('protocol.right') }}
                  +input('lighting.light_power_low_light_right', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
              tr
                td {{ $t('protocol.mismatchCode') }}
                td(colspan=3)
                  +input('lighting.light_power_code', '')(:placeholder="$t('protocol.mismatchCode')" hide-details).mt-0.pa-1
        v-divider
        .caption.grey--text.pt-2.px-4 {{ $t('protocol.lightingNoteOne') }}
        .caption.grey--text.pb-1.px-4 {{ $t('protocol.lightingNoteTwo') }}
        v-divider

        v-card-subtitle.mt-2.font-weight-bold {{ $t('protocol.lightSignalLights') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.nameLightSignalLight')
                +tableHeadSimple('protocol.quantity')
                +tableHeadSimple('protocol.colorLabel')
                +tableHeadSimple('protocol.conformity')
            tbody
              tr
                td {{ $t('protocol.frontTurnSignals') }}
                td 2
                td {{ $t('protocol.autoYellow') }}
                td
                  +radioGroup('signal_lights.front_turn_signals')
              tr
                td {{ $t('protocol.sideTurnSignals') }}
                td 2
                td {{ $t('protocol.autoYellow') }}
                td
                  +radioGroup('signal_lights.side_turn_signals')
              tr
                td {{ $t('protocol.rearTurnSignals') }}
                td 2
                td {{ $t('protocol.autoYellow') }}
                td
                  +radioGroup('signal_lights.rear_turn_signals')
              tr
                td {{ $t('protocol.brakingSignals') }}
                td 2/4
                td {{ $t('protocol.red') }}
                td
                  +radioGroup('signal_lights.braking_signals')
              tr
                td {{ $t('protocol.brakingSignalAdditional') }}
                td 1
                td {{ $t('protocol.red') }}
                td
                  +radioGroup('signal_lights.braking_signal_additional')
              tr
                td {{ $t('protocol.frontPositionLights') }}
                td 2
                td {{ $t('protocol.white') }}
                td
                  +radioGroup('signal_lights.front_position_lights')
              tr
                td {{ $t('protocol.rearPositionLights') }}
                td 2/4
                td {{ $t('protocol.red') }}
                td
                  +radioGroup('signal_lights.rear_position_lights')
              tr
                td {{ $t('protocol.sidePositionLamps') }}
                td
                td {{ $t('protocol.autoYellow') }}/({{ $t('protocol.red') }})
                td
                  +radioGroup('signal_lights.side_position_lamps')
              tr
                td {{ $t('protocol.alarmLamps') }}
                td -----
                td {{ $t('protocol.autoYellow') }}
                td
                  +radioGroup('signal_lights.alarm_lamps')
              tr
                td {{ $t('protocol.licensePlateLights') }}
                td 1/2
                td {{ $t('protocol.white') }}
                td
                  +radioGroup('signal_lights.license_plate_lights')
              tr
                td {{ $t('protocol.rearFogLights') }}
                td 1/2
                td {{ $t('protocol.red') }}
                td
                  +radioGroup('signal_lights.rear_fog_lights')
              tr
                td {{ $t('protocol.frontFogLights') }}
                td 2
                td {{ $t('protocol.white') }}
                td
                  +radioGroup('signal_lights.front_fog_lights')
              tr
                td {{ $t('protocol.reversingLights') }}
                td 1/2/4
                td {{ $t('protocol.white') }}
                td
                  +radioGroup('signal_lights.reversing_lights')
              tr
                td {{ $t('protocol.rearReflectorsNonTriangular') }}
                td 2
                td {{ $t('protocol.red') }}
                td
                  +radioGroup('signal_lights.rear_reflectors_non_triangular')
              tr
                td {{ $t('protocol.frontReflectorsNonTriangular') }}
                td 2/4
                td {{ $t('protocol.autoYellow') }}
                td
                  +radioGroup('signal_lights.front_reflectors_non_triangular')
              tr
                td {{ $t('protocol.sideReflectors') }}
                td
                td {{ $t('protocol.autoYellow') }}/({{ $t('protocol.red') }})
                td
                  +radioGroup('signal_lights.side_reflectors')
              tr
                td {{ $t('protocol.rearReflectorsTriangular') }}
                td 2/4
                td {{ $t('protocol.red') }}
                td
                  +radioGroup('signal_lights.rear_reflectors_triangular')
              tr
                td {{ $t('protocol.contourLightsFrontRear') }}
                td 2/2
                td {{ $t('protocol.white') }}/({{ $t('protocol.red') }})
                td
                  +radioGroup('signal_lights.contour_lights_frontRear')
        v-divider
        .caption.grey--text.py-2.px-4 {{ $t('protocol.lightSignalLightsNoteOne') }}
        v-divider
        .caption.py-1.px-4 {{ $t('protocol.note') }}:
        .caption.grey--text.pb-1.px-4 {{ $t('protocol.lightSignalLightsNoteTwo') }}
        .caption.grey--text.pb-2.px-4 {{ $t('protocol.lightSignalLightsNoteThree') }}

        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.nameCharacteristicOrRequirement')
                +tableHeadSimple('protocol.standard')
                +tableHeadSimple('protocol.measurementResultAndCompliance')
            tbody
              tr
                td {{ $t('protocol.turnSignalFlickerFrequency') }}
                td (90±30) {{ $t('unitMinute') }}
                  |
                  sup -1
                td
                  +input('signal_lights.turn_signal_flicker_frequency', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
              tr
                td {{ $t('protocol.turnIndicatorsOneSideVehicle') }}
                td {{ $t('protocol.workInOnePhase') }}
                td
                  +radioGroup('signal_lights.turn_indicators_one_side_vehicle')
              tr
                td {{ $t('protocol.alarmSignal') }}
                td {{ $t('protocol.synchronousOperation') }}
                td
                  +radioGroup('signal_lights.alarm_signal', 'hideNotSpecified')
              tr
                td {{ $t('protocol.mismatchCode') }}
                td(colspan=2)
                  +input('lighting.signal_lights_code', '')(:placeholder="$t('protocol.mismatchCode')" hide-details).mt-0.pa-1
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.mirrorsRearView') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.availabilityConditionFunctionality', 'mirrors.availability_condition_functionality', 'mirrors.availability_condition_functionality_code')
              +tableRowRadioInput('protocol.technicalCondition', 'mirrors.technical_condition', 'mirrors.technical_condition_code', '', '5.9.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.windshieldWiperAndWindshieldWasher') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.windshieldAvailabilityConditionFunctionality', 'windscreen_wiper.availability_condition_functionality', 'windscreen_wiper.availability_condition_functionality_code')
              +tableRowRadioInput('protocol.technicalCondition', 'windscreen_wiper.technical_condition', 'windscreen_wiper.technical_condition_code', '', '5.10.2')
              tr
                td(width="500px") {{ $t('protocol.frequencyBrushMovement') }}
                td
                  .d-flex.align-center
                    +radioGroup('windscreen_wiper.frequency_brush_movement')
                    +input('windscreen_wiper.frequency_brush_movement_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details style="width: 200px").mt-0.pa-1
                td
                  +input('windscreen_wiper.frequency_brush_movement_code', '')(:placeholder="$t('protocol.mismatchCode')" hide-details).mt-0.pa-1
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.engineAndSystems') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.stateConstructionFunctionality', 'engine.state_construction_functionality', 'engine.state_construction_functionality_code')
              +tableRowRadioInput('protocol.technicalCondition', 'engine.technical_condition', 'engine.technical_condition_code', '', '5.11.2')
              +tableRowRadioInput('protocol.leaksOperatingFluids', 'engine.leaks_operating_fluids', 'engine.leaks_operating_fluids_code')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.naturalEnvironmentTitle') }}
        v-card-subtitle.pt-2.font-weight-bold {{ $t('protocol.contentBurnsCarbonMonoxideHydrocarbonsSmokyBurns') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                th(colspan="8") {{ $t('protocol.smokyBurnsDieselsGasDiesels') }}
              tr
                th.text-center(colspan="5") {{ $t('protocol.measurementNumber') }}
                th.text-center {{ $t('protocol.averageValue4TestCycles') }}
                th.text-center {{ $t('protocol.adjustedValueVehicleEngine') }}
                th.text-center {{ $t('protocol.conformity') }}
            tbody
              tr
                td {{ $t('protocol.indicator') }}
                td.text-center 1
                td.text-center 2
                td.text-center 3
                td.text-center 4
                td(rowspan="2")
                  +input('negative_influence.average_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td(rowspan="2")
                  +input('negative_influence.adjusted_value', '')(:placeholder="$t('protocol.measuredValue')" hide-details).mt-0.pa-1
                td(rowspan="2")
                  +radioGroup('negative_influence.conformity')
              tr
                td.bb-1 {{ $t('unitSmoke') }}
                td.px-2.bb-1
                  +input('negative_influence.measurement_number1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 110px").mt-0.pa-1
                td.px-2.bb-1
                  +input('negative_influence.measurement_number2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 110px").mt-0.pa-1
                td.px-2.bb-1
                  +input('negative_influence.measurement_number3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 110px").mt-0.pa-1
                td.px-2.bb-1
                  +input('negative_influence.measurement_number4', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 110px").mt-0.pa-1

        .caption.grey--text.py-1.px-4 {{ $t('protocol.smokyNote') }}
        v-divider

        v-simple-table.default-table.mt-6(dense)
          template(v-slot:default)
            thead
              tr
                th(colspan="12") {{ $t('protocol.carbonMonoxideConcentrationBefore2009') }}
              tr
                th.text-center(rowspan="3") {{ $t('protocol.fuel') }}
                th.text-center(rowspan="3") {{ $t('protocol.presenceNeutralizer') }}
                th.text-center(rowspan="3") {{ $t('protocol.testMode') }}
                th.text-center(colspan="3") {{ $t('protocol.contentCO') }}
                th.text-center(colspan="5") {{ $t('protocol.contentCH') }}
                th.text-center(rowspan="3" style="width: 235px") {{ $t('protocol.conformityMark') }}
              tr
                th.text-center(rowspan="2" colspan="2") {{ $t('protocol.standardNoMore') }}
                th.text-center(rowspan="2") {{ $t('protocol.justResult') }}
                th.text-center(colspan="4") {{ $t('protocol.standardNoMore') }}
                th.text-center(rowspan="3") {{ $t('protocol.justResult') }}
              tr
                th.text-center(colspan="2") {{ $t('protocol.numberCylindersLessThan4') }}
                th.text-center(colspan="2") {{ $t('protocol.numberCylindersMoreThan4') }}

            tbody.text-center
              tr
                td(rowspan="2") {{ $t('protocol.petrol') }}
                td(rowspan="2") {{ $t('protocol.notEquipped') }}
                td n {{ $t('protocol.min') }}
                td(colspan="2") 3,5
                td.px-1
                  +input('concentration_carbonMonoxide_hydrocarbons_before2009.CO_content1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 120px").mt-0.pa-1
                td(colspan="2") 1200
                td(colspan="2") 2500
                td.px-1
                  +input('concentration_carbonMonoxide_hydrocarbons_before2009.CH_content1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 120px").mt-0.pa-1
                td
                  +radioGroup('concentration_carbonMonoxide_hydrocarbons_before2009.COCH_conformity1')
              tr
                td n {{ $t('protocol.sub') }}
                td(colspan="2") 2,5
                td.px-1
                  +input('concentration_carbonMonoxide_hydrocarbons_before2009.CO_content2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 120px").mt-0.pa-1
                td(colspan="2") 600
                td(colspan="2") 1000
                td.px-1
                  +input('concentration_carbonMonoxide_hydrocarbons_before2009.CH_content2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 120px").mt-0.pa-1
                td
                  +radioGroup('concentration_carbonMonoxide_hydrocarbons_before2009.COCH_conformity2')
              tr
                td(rowspan="2") {{ $t('protocol.gas') }}
                td(rowspan="2") {{ $t('protocol.notEquipped') }}
                td n {{ $t('protocol.min') }}
                td 1,5 ({{ $t('protocol.lng') }})
                td 3,5 ({{ $t('protocol.lpg') }})
                td.px-1
                  +input('concentration_carbonMonoxide_hydrocarbons_before2009.CO_content3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 120px").mt-0.pa-1
                td 600 ({{ $t('protocol.lng') }})
                td 1200 ({{ $t('protocol.lpg') }})
                td 1800 ({{ $t('protocol.lng') }})
                td 2500 ({{ $t('protocol.lpg') }})
                td.px-1
                  +input('concentration_carbonMonoxide_hydrocarbons_before2009.CH_content3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 120px").mt-0.pa-1
                td
                  +radioGroup('concentration_carbonMonoxide_hydrocarbons_before2009.COCH_conformity3')
              tr
                td n {{ $t('protocol.sub') }}
                td 1,0 ({{ $t('protocol.lng') }})
                td 1,5 ({{ $t('protocol.lpg') }})
                td.px-1
                  +input('concentration_carbonMonoxide_hydrocarbons_before2009.CO_content4', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 120px").mt-0.pa-1
                td 300 ({{ $t('protocol.lng') }})
                td 600 ({{ $t('protocol.lpg') }})
                td 600 ({{ $t('protocol.lng') }})
                td 1000 ({{ $t('protocol.lpg') }})
                td.px-1
                  +input('concentration_carbonMonoxide_hydrocarbons_before2009.CH_content4', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 120px").mt-0.pa-1
                td
                  +radioGroup('concentration_carbonMonoxide_hydrocarbons_before2009.COCH_conformity4')
              tr
                td(rowspan="2") {{ $t('protocol.petrol') }}
                td(rowspan="2") * {{ $t('protocol.equipped') }}
                td n {{ $t('protocol.min') }}
                td 1,0 ({{ $t('protocol.oxide') }}.)
                td 0,5 ({{ $t('protocol.trick') }}.)
                td
                td(colspan="2") 600  ({{ $t('protocol.oxide') }}.)
                td(colspan="2") 100  ({{ $t('protocol.trick') }}.)
                td
                td
                  +radioGroup('concentration_carbonMonoxide_hydrocarbons_before2009.COCH_conformity5')
              tr
                td.bb-1 n {{ $t('protocol.sub') }}
                td.bb-1 0,6 ({{ $t('protocol.oxide') }}.)
                td.bb-1 0,3 ({{ $t('protocol.trick') }}.)
                td.bb-1
                td.bb-1(colspan="2") 300 ({{ $t('protocol.oxide') }}.)
                td.bb-1(colspan="2") 100 ({{ $t('protocol.trick') }}.)
                td.bb-1
                td.bb-1
                  +radioGroup('concentration_carbonMonoxide_hydrocarbons_before2009.COCH_conformity6')
        .caption.grey--text.py-1.px-4 {{ $t('protocol.carbonMonoxideConcentrationBefore2009Note') }}
        v-divider

        v-simple-table.default-table.mt-6(dense)
          template(v-slot:default)
            thead
              tr
                th(colspan="9") {{ $t('protocol.carbonMonoxideConcentrationAfter2009') }}
              tr
                th.text-center(rowspan="2") {{ $t('protocol.levelEnvironmentalStandards') }}
                th.text-center(colspan="2") {{ $t('protocol.effectiveDateEnvironmentalNorm') }}
                th.text-center(colspan="2" rowspan="2") {{ $t('protocol.coefficientExcessAir') }}
                th.text-center(colspan="4") {{ $t('protocol.volumeFractionCO') }}
              tr
                th.text-center {{ $t('protocol.inEU') }}
                th.text-center {{ $t('protocol.inUkraine') }}
                th.text-center n
                  |
                  sub {{ $t('protocol.min') }}
                th.text-center n
                  |
                  sub {{ $t('protocol.sub') }}
                th.text-center n
                  |
                  sub {{ $t('protocol.min') }}
                th.text-center n
                  |
                  sub {{ $t('protocol.sub') }}
            tbody.text-center
              tr
                td {{ $t('protocol.euro-2') }}
                td 01.01.1996 5
                td 2006-2007 6
                td 1 ± 0,03
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td 0,5
                td 0,3
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td {{ $t('protocol.euro-3') }}
                td 01.01.2001 7
                td 01.01.2013
                td 1 ± 0,03
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro3_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td 0,3
                td 0,2
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro3_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro3_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td
                td 01.01.2002 8
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro3_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro3_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro3_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td
                td 01.01.2003 9
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro3_3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro3_3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro3_3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td {{ $t('protocol.euro-4') }}
                td 01.01.2006 10
                td 01.01.2014
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro4_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro4_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro4_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td
                td 01.01.2007 11
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro4_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro4_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro4_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td {{ $t('protocol.euro-5') }}
                td 01.01.2011 12
                td 01.01.2016
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro5_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro5_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro5_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td
                td 01.01.2012 13
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro5_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro5_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro5_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td {{ $t('protocol.euro-6') }}
                td 01.01.2014 14
                td 01.01.2018
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro6_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro6_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro6_1', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td
                td 01.09.2014 15
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro6_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro6_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro6_2', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td
                td 01.09.2015 16
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro6_3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro6_3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro6_3', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td
                td 01.09.2016 17
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.coefficient_excess_air_euro6_4', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                td
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_min_euro6_4', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
                td
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.CO_volume_fraction_max_euro6_4', '')(:placeholder="$t('protocol.value')" hide-details style="max-width: 150px").mt-0.pa-1.mx-auto
              tr
                td {{ $t('protocol.mismatchCode') }}
                td(colspan="8")
                  +input('concentration_carbonMonoxide_hydrocarbons_after2009.discrepancy_codes', '')(:placeholder="$t('protocol.mismatchCode')" hide-details).mt-0.pa-1
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.brakeSystemsTitle') }}
        v-card-subtitle.pt-2.font-weight-bold {{ $t('protocol.brakeSystemsSubTitle') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                th.text-center(rowspan="2") {{ $t('protocol.brakeSystem') }}
                th.text-center(colspan="2") {{ $t('protocol.totalSpecificBrakingForce') }}
                th.text-center(colspan="5") {{ $t('protocol.coefficientBrakingForcesAxle') }}
                th.text-center(colspan="2") {{ $t('protocol.durationOperation') }}
                th.text-center(colspan="3") {{ $t('protocol.effortsGoverning') }}
              tr
                th.text-center {{ $t('protocol.standard') }}
                th.text-center {{ $t('protocol.justResult') }}
                th.text-center {{ $t('protocol.standard') }}
                th.text-center 1 {{ $t('shaft') }}
                th.text-center 2 {{ $t('shaft') }}
                th.text-center 3 {{ $t('shaft') }}
                th.text-center 4 {{ $t('shaft') }}
                th.text-center {{ $t('protocol.standard') }}
                th.text-center {{ $t('protocol.justResult') }}
                th.text-center {{ $t('protocol.standard') }}
                th.text-center {{ $t('protocol.justResult') }}
                th.text-center(style="width: 235px") {{ $t('protocol.conformity') }}
            tbody.text-center
              tr
                td {{ $t('protocol.rgs') }}
                td
                  .noWrap {{ $t('protocol.m1m3n1') }} >= 0,50
                  .noWrap {{ $t('protocol.n2n3') }} >= 0,45
                  .noWrap {{ $t('protocol.o1o2o3o4') }} >= 0,43
                td.px-0
                  +input('brake_systems.rgs_braking_force_result', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td <=30
                td.px-0
                  +input('brake_systems.irregularity_shaft1', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td.px-0
                  +input('brake_systems.irregularity_shaft2', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td.px-0
                  +input('brake_systems.irregularity_shaft3', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td.px-0
                  +input('brake_systems.irregularity_shaft4', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  div <=0,8
                  div <=0,5
                td.px-0
                  +input('brake_systems.rgs_duration_operation', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  div <=490 (М1)
                  .noWrap <=686 ({{$t('protocol.other')}})
                td.px-0
                  +input('brake_systems.rgs_effort', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('brake_systems.rgs_conformity')
              tr
                td {{ $t('protocol.sgs') }}
                td >=0,16
                td.px-0
                  +input('brake_systems.sgs_braking_force_result', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td ------
                td ------
                td ------
                td ------
                td ------
                td ------
                td ------
                td
                  div <=392 (М1)
                  .noWrap <=588 ({{$t('protocol.other')}})
                td.px-0
                  +input('brake_systems.sgs_effort', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('brake_systems.sgs_conformity')
              tr
                td(colspan="2") {{ $t('protocol.mismatchCode') }}
                td(colspan="11")
                  +input('brake_systems.mismatch_code', '')(:placeholder="$t('protocol.mismatchCode')" hide-details).mt-0.pa-1
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.controlSystem') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.objectAndSubjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')

            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'control_system.construction_condition', 'control_system.construction_condition_code', '', '5.14.1')
              +tableRowRadioInput('protocol.technicalCondition', 'control_system.technical_condition', 'control_system.technical_condition_code', '', '5.14.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.totalAngularInterval') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                td {{ $t('protocol.carCategoryLabel') }}
                td {{ $t('protocol.notMoreThan') }}, °
                td {{ $t('protocol.measuredValue') }}, °
                td {{ $t('protocol.mismatchCode') }}

            tbody
              tr
                td М1, М1G, М2, М2G, N1, N1G
                td 10° (25°)
                td
                  +input('totalAngularInterval.category1_value', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('totalAngularInterval.category1_code', '')(:placeholder="$t('protocol.mismatchCode')" hide-details).mt-0.pa-1.mx-auto
              tr
                td M3, M3G, N2, N2G, N3, N3G
                td 20° (25°)
                td
                  +input('totalAngularInterval.category2_value', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('totalAngularInterval.category2_code', '')(:placeholder="$t('protocol.mismatchCode')" hide-details).mt-0.pa-1.mx-auto

        v-divider
        .caption.grey--text.pt-1.px-4 {{ $t('protocol.totalAngularIntervalNote') }}
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.requirementsGasEquipment') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.stateConstructionFunctionalityGeneral', 'gas_equipment.construction_condition', 'gas_equipment.construction_condition_code')
              +tableRowRadioInput('protocol.technicalCondition', 'gas_equipment.technical_condition', 'gas_equipment.technical_condition_code')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.requirementsEnginesWithObdEobd') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.technicalConditionFunctionality', 'engine_requirements.technical_condition', 'engine_requirements.technical_condition_code')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.frameBodyElements') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'frame_body_elements.construction_condition', 'frame_body_elements.construction_condition_code', '', '5.16.1')
              +tableRowRadioInput('protocol.technicalCondition', 'frame_body_elements.technical_condition', 'frame_body_elements.technical_condition_code', '', '5.16.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.towingHitchTrailerBolt') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'towing_hitch_trailer_bolt.construction_condition', 'towing_hitch_trailer_bolt.construction_condition_code', '', '5.17.1')
              +tableRowRadioInput('protocol.technicalCondition', 'towing_hitch_trailer_bolt.technical_condition', 'towing_hitch_trailer_bolt.technical_condition_code', '', '5.17.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.frontRearTowingDevices') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'front_rear_towing_devices.construction_condition', 'front_rear_towing_devices.construction_condition_code', '', '5.18.1')
              +tableRowRadioInput('protocol.technicalCondition', 'front_rear_towing_devices.technical_condition', 'front_rear_towing_devices.technical_condition_code', '', '5.18.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.cargoPlatform') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'cargo_platform.construction_condition', 'cargo_platform.construction_condition_code', '', '5.19.1')
              +tableRowRadioInput('protocol.technicalCondition', 'cargo_platform.technical_condition', 'cargo_platform.technical_condition_code', '', '5.19.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.sparePneumaticWheel') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'spare_pneumatic_wheel.construction_condition', 'spare_pneumatic_wheel.construction_condition_code', '', '5.20.1')
              +tableRowRadioInput('protocol.technicalCondition', 'spare_pneumatic_wheel.technical_condition', 'spare_pneumatic_wheel.technical_condition_code', '', '5.20.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.powerTransmissionControlMechanisms') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'power_transmission_control_mechanisms.construction_condition', 'power_transmission_control_mechanisms.construction_condition_code', '', '5.21.1')
              +tableRowRadioInput('protocol.technicalCondition', 'power_transmission_control_mechanisms.technical_condition', 'power_transmission_control_mechanisms.technical_condition_code', '', '5.21.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.bridgesAndAxes') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'bridges_axes.construction_condition', 'bridges_axes.construction_condition_code', '', '5.22.1')
              +tableRowRadioInput('protocol.technicalCondition', 'bridges_axes.technical_condition', 'bridges_axes.technical_condition_code', '', '5.22.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.meansFixing') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'means_fixing.construction_condition', 'means_fixing.construction_condition_code', '', '5.23.1')
              +tableRowRadioInput('protocol.technicalCondition', 'means_fixing.technical_condition', 'means_fixing.technical_condition_code', '', '5.23.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.speedometerTachometerDevices') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'speedometer_tachometer_devices.construction_condition', 'speedometer_tachometer_devices.construction_condition_code', '', '5.24.1')
              +tableRowRadioInput('protocol.technicalCondition', 'speedometer_tachometer_devices.technical_condition', 'speedometer_tachometer_devices.technical_condition_code', '', '5.24.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.equipmentSoundSignalFireExtinguisher') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                +tableHeadSimple('protocol.subjectInspection')
                +tableHeadSimple('protocol.conformity')
                +tableHeadSimple('protocol.mismatchCode')
            tbody
              +tableRowRadioInput('protocol.constructionCondition', 'equipment_sound_signal_fire_extinguisher.construction_condition', 'equipment_sound_signal_fire_extinguisher.construction_condition_code', '', '5.25.1')
              +tableRowRadioInput('protocol.technicalCondition', 'equipment_sound_signal_fire_extinguisher.technical_condition', 'equipment_sound_signal_fire_extinguisher.technical_condition_code', '', '5.25.2')
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.externalNoiseLevel') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                th(colspan="2") {{ $t('protocol.requirements') }}
                th {{ $t('protocol.standardNotMoreThan') }}, {{ $t('unitSound') }} ***
                th {{ $t('protocol.windSpeed') }}, {{ $t('unitMeterSec') }}
                th {{ $t('protocol.backgroundNoise') }}, {{ $t('unitSound') }} **
                th {{ $t('protocol.testResult') }}, {{ $t('unitSound') }} ***
                th(style="width: 235px") {{ $t('protocol.conformity') }}
            tbody
              tr
                td(colspan="2") {{ $t('protocol.carsAndTrucks') }}
                td 87
                td
                  +input('external_noise_level.wind_speed.cars_trucks', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.background_noise.cars_trucks', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.test_result.cars_trucks', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('external_noise_level.conformity.cars_trucks')
              tr
                td(rowspan="2") {{ $t('protocol.busesMaximumWeightOver3500') }}
                td — 150 та більше
                td 90
                td
                  +input('external_noise_level.wind_speed.buses_mass_over3500_engine_over150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.background_noise.buses_mass_over3500_engine_over150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.test_result.buses_mass_over3500_engine_over150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('external_noise_level.conformity.buses_mass_over3500_engine_over150')
              tr
                td — менше ніж 150;
                td 93
                td
                  +input('external_noise_level.wind_speed.buses_mass_over3500_engine_less150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.background_noise.buses_mass_over3500_engine_less150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.test_result.buses_mass_over3500_engine_less150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('external_noise_level.conformity.buses_mass_over3500_engine_less150')
              tr
                td(rowspan="2") {{ $t('protocol.busesTrucksMaximumWeight') }}
                td — не більше ніж 2000;
                td 88
                td
                  +input('external_noise_level.wind_speed.buses_trucks_mass_less2000', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.background_noise.buses_trucks_mass_less2000', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.test_result.buses_trucks_mass_less2000', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('external_noise_level.conformity.buses_trucks_mass_less2000')
              tr
                td — понад 2000, але не більше ніж 3500
                td 89
                td
                  +input('external_noise_level.wind_speed.buses_trucks_mass_over2000_less3500', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.background_noise.buses_trucks_mass_over2000_less3500', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.test_result.buses_trucks_mass_over2000_less3500', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('external_noise_level.conformity.buses_trucks_mass_over2000_less3500')
              tr
                td(rowspan="3") {{ $t('protocol.trucksRoadTrainsMaximumWeightOver3500') }}
                td — менше ніж 75;
                td 91
                td
                  +input('external_noise_level.wind_speed.road_trains_mass_over3500_engine_less75', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.background_noise.road_trains_mass_over3500_engine_less75', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.test_result.road_trains_mass_over3500_engine_less75', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('external_noise_level.conformity.road_trains_mass_over3500_engine_less75')
              tr
                td — 75 та більше, але менше ніж 150;
                td 93
                td
                  +input('external_noise_level.wind_speed.road_trains_mass_over3500_engine_over75_less150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.background_noise.road_trains_mass_over3500_engine_over75_less150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.test_result.road_trains_mass_over3500_engine_over75_less150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('external_noise_level.conformity.road_trains_mass_over3500_engine_over75_less150')
              tr
                td — 150 та більше
                td 94
                td
                  +input('external_noise_level.wind_speed.road_trains_mass_over3500_engine_over150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.background_noise.road_trains_mass_over3500_engine_over150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +input('external_noise_level.test_result.road_trains_mass_over3500_engine_over150', '')(:placeholder="$t('protocol.value')" hide-details).mt-0.pa-1.mx-auto
                td
                  +radioGroup('external_noise_level.conformity.road_trains_mass_over3500_engine_over150')
        v-divider
        .caption.grey--text.pt-1.px-4 {{ $t('protocol.externalNoiseLevelNoteOne') }}
        .caption.grey--text.pt-1.px-4 {{ $t('protocol.externalNoiseLevelNoteTwo') }}
        .caption.grey--text.pt-1.px-4 {{ $t('protocol.externalNoiseLevelNoteThree') }}
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.inspectionCarryThrough') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            thead
              tr
                th {{ $t('protocol.post') }}
                th {{ $t('protocol.initialsSurname') }}
            tbody
              tr
                td
                  +input('inspection.post1', '')(hide-details).mt-0.pa-1.mx-auto
                td
                  +input('inspection.initials_surname1', '')(hide-details).mt-0.pa-1.mx-auto
              tr
                td
                  +input('inspection.post2', '')(hide-details).mt-0.pa-1.mx-auto
                td
                  +input('inspection.initials_surname2', '')(hide-details).mt-0.pa-1.mx-auto
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold 8. {{ $t('protocol.mismatchCodes') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            tbody
              tr
                td
                  +input('mismatch_codes', '')(:placeholder="$t('protocol.mismatchCodes')" hide-details).mt-0.pa-1.mx-auto
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.opinionsAndInterpretations') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            tbody
              tr
                td
                  +input('mismatch_codes', '')(hide-details).mt-0.pa-1.mx-auto
        v-divider

        v-card-subtitle.title.mt-4.font-weight-bold {{ $t('protocol.conclusion') }}
        v-simple-table.default-table(dense)
          template(v-slot:default)
            tbody
              tr
                td {{ $t('protocol.noteCompliance') }}
                td
                  v-radio-group.default-input(v-model="conclusion" row)
                    v-radio(:label="$t('protocol.match')" :value="true")
                    v-radio(:label="$t('protocol.notMatch')" :value="false")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validate, validationField } from '@/mixins/validation'

export default {
  name: 'result-protocol',
  data() {
    return {
      car_parameters: {
        numberChassis: null,
        category: null,
        manufacturer: null,
        model: null,
        car_license_plate: null,
        odometr: null,
        fuel_type_id: null,
        manufacture_date: null,
        state_registration_date: null,
        turbine_compressor: null,
        reequipment_doc_date: null,
        reequipment_doc_number: null,
        essenceReequipment: null,
        numberCylinders: null,
        ecologicalLeve: null,
        color: null,
        purpose: null,
        isTaxi: '',
        isDangerousGoods: '',
        air_temperature: null,
        humidity: null,
        atmospheric_pressure: null,
      },
      test_results: {
        passenger_capacity: '',
        passenger_capacity_code: '',
        condition_construction: '',
        condition_construction_code: '',
        seat_belts: '',
        seat_belts_code: '',
        cabin_lock: '',
        cabin_lock_code: '',
        antiglare_device: '',
        antiglare_device_code: '',
        ejection_devices: '',
        ejection_devices_code: '',
        bumper: '',
        bumper_code: '',
        reEquipment: '',
        reEquipment_code: ''
      },
      pneumatic_tires: {
        number_condition: '',
        number_condition_code: '',
        technical_condition: '',
        technical_condition_code: '',
      },
      tire_tread: {
        tire_tread_one: null,
        tire_tread_two: null,
        tire_tread_three: null,
      },
      tire_pressure: {
        tire_pressure_shaft_one: null,
        tire_pressure_shaft_two: null,
        tire_pressure_shaft_three: null,
        tire_pressure_shaft_four: null
      },
      wheels: {
        number_and_condition_wheels: '',
        number_and_condition_wheels_code: null,

        technical_condition: '',
        technical_condition_code: null,

        tightening_wheel_bolts: '',
        tightening_wheel_bolts_code: ''
      },
      protective_devices: {
        rear_protective_devices: '',
        rear_protective_devices_code: '',

        side_protective_devices: '',
        side_protective_devices_code: ''
      },

      glass: {
        glass_requirements_construction: '',
        glass_requirements_construction_code: '',

        glass_technical_condition: '',
        glass_technical_condition_code: '',

        light_transmission_windshield_value: null,
        light_transmission_front_left_value: null,
        light_transmission_front_right_value: null,
        light_transmission_back_right_value: null,
        light_transmission_back_left_value: null,
        light_transmission_rear_value: null,

        light_transmission_windshield: '',
        light_transmission_front_left: '',
        light_transmission_front_right: '',
        light_transmission_back_right: '',
        light_transmission_back_left: '',
        light_transmission_rear: '',

        glass_light_transmission_code: null
      },

      lighting: {
        high_beam_headlights_availability: '',
        low_beam_headlights_availability: '',
        front_fog_lamp_availability: '',

        high_beam_headlights_technical: '',
        low_beam_headlights_technical: '',
        front_fog_lamp_technical: '',

        high_beam_headlights_direction: '',
        low_beam_headlights_direction: '',
        front_fog_lamp_direction: '',

        high_beam_headlights_distribution: '',
        low_beam_headlights_distribution: '',
        front_fog_lamp_distribution: '',

        light_power_high_beam: null,
        light_power_front_fog_lamp_left: null,
        light_power_front_fog_lamp_right: null,

        light_power_intense_lighting_left: null,
        light_power_intense_lighting_right: null,

        light_power_low_light_left: null,
        light_power_low_light_right: null,

        light_power_code: null
      },

      signal_lights: {
        front_turn_signals: '',
        side_turn_signals: '',
        rear_turn_signals: '',
        braking_signals: '',
        braking_signal_additional: '',
        front_position_lights: '',
        rear_position_lights: '',
        side_position_lamps: '',
        alarm_lamps: '',
        license_plate_lights: '',
        rear_fog_lights: '',
        front_fog_lights: '',
        reversing_lights: '',
        rear_reflectors_non_triangular: '',
        front_reflectors_non_triangular: '',
        side_reflectors: '',
        rear_reflectors_triangular: '',
        contour_lights_frontRear: '',
        turn_signal_flicker_frequency: null,
        turn_indicators_one_side_vehicle: '',
        alarm_signal: '',
        signal_lights_code: null
      },

      mirrors: {
        availability_condition_functionality: '',
        availability_condition_functionality_code: '',

        technical_condition: '',
        technical_condition_code: '',
      },

      windscreen_wiper: {
        availability_condition_functionality: '',
        availability_condition_functionality_code: '',

        technical_condition: '',
        technical_condition_code: '',

        frequency_brush_movement: '',
        frequency_brush_movement_value: null,
        frequency_brush_movement_code: null
      },

      engine: {
        state_construction_functionality: '',
        state_construction_functionality_code: null,

        technical_condition: '',
        technical_condition_code: null,

        leaks_operating_fluids: '',
        leaks_operating_fluids_code: null,
      },

      negative_influence: {
        measurement_number1: null,
        measurement_number2: null,
        measurement_number3: null,
        measurement_number4: null,
        average_value: null,
        adjusted_value: null,
        conformity: ''
      },

      concentration_carbonMonoxide_hydrocarbons_before2009: {
        CO_content1: null,
        CO_content2: null,
        CO_content3: null,
        CO_content4: null,

        CH_content1: null,
        CH_content2: null,
        CH_content3: null,
        CH_content4: null,

        COCH_conformity1: '',
        COCH_conformity2: '',
        COCH_conformity3: '',
        COCH_conformity4: '',
        COCH_conformity5: '',
        COCH_conformity6: '',

      },

      concentration_carbonMonoxide_hydrocarbons_after2009: {
        coefficient_excess_air_euro2: null,
        coefficient_excess_air_euro3_1: null,
        coefficient_excess_air_euro3_2: null,
        coefficient_excess_air_euro3_3: null,
        coefficient_excess_air_euro4_1: null,
        coefficient_excess_air_euro4_2: null,
        coefficient_excess_air_euro5_1: null,
        coefficient_excess_air_euro5_2: null,
        coefficient_excess_air_euro6_1: null,
        coefficient_excess_air_euro6_2: null,
        coefficient_excess_air_euro6_3: null,
        coefficient_excess_air_euro6_4: null,

        CO_volume_fraction_min_euro2: null,
        CO_volume_fraction_min_euro3_1: null,
        CO_volume_fraction_min_euro3_2: null,
        CO_volume_fraction_min_euro3_3: null,
        CO_volume_fraction_min_euro4_1: null,
        CO_volume_fraction_min_euro4_2: null,
        CO_volume_fraction_min_euro5_1: null,
        CO_volume_fraction_min_euro5_2: null,
        CO_volume_fraction_min_euro6_1: null,
        CO_volume_fraction_min_euro6_2: null,
        CO_volume_fraction_min_euro6_3: null,
        CO_volume_fraction_min_euro6_4: null,

        CO_volume_fraction_max_euro2: null,
        CO_volume_fraction_max_euro3_1: null,
        CO_volume_fraction_max_euro3_2: null,
        CO_volume_fraction_max_euro3_3: null,
        CO_volume_fraction_max_euro4_1: null,
        CO_volume_fraction_max_euro4_2: null,
        CO_volume_fraction_max_euro5_1: null,
        CO_volume_fraction_max_euro5_2: null,
        CO_volume_fraction_max_euro6_1: null,
        CO_volume_fraction_max_euro6_2: null,
        CO_volume_fraction_max_euro6_3: null,
        CO_volume_fraction_max_euro6_4: null,

        discrepancy_codes: null
      },

      brake_systems: {
        rgs_braking_force_result: null,
        sgs_braking_force_result: null,

        irregularity_shaft1: null,
        irregularity_shaft2: null,
        irregularity_shaft3: null,
        irregularity_shaft4: null,
        rgs_duration_operation: null,

        rgs_effort: null,
        sgs_effort: null,

        rgs_conformity: '',
        sgs_conformity: '',

        mismatch_code: null
      },

      control_system: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      totalAngularInterval: {
        category1_value: null,
        category1_code: null,
        category2_value: null,
        category2_code: null,
      },

      gas_equipment: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      engine_requirements: {
        technical_condition: '',
        technical_condition_code: null,
      },
      frame_body_elements: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      towing_hitch_trailer_bolt: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      front_rear_towing_devices: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      cargo_platform: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      spare_pneumatic_wheel: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      power_transmission_control_mechanisms: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      bridges_axes: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      means_fixing: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      speedometer_tachometer_devices: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      equipment_sound_signal_fire_extinguisher: {
        construction_condition: '',
        construction_condition_code: null,

        technical_condition: '',
        technical_condition_code: null,
      },

      external_noise_level: {
        wind_speed: {
          cars_trucks: null,
          buses_mass_over3500_engine_over150: null,
          buses_mass_over3500_engine_less150: null,
          buses_trucks_mass_less2000: null,
          buses_trucks_mass_over2000_less3500: null,
          road_trains_mass_over3500_engine_less75: null,
          road_trains_mass_over3500_engine_over75_less150: null,
          road_trains_mass_over3500_engine_over150: null
        },
        background_noise: {
          cars_trucks: null,
          buses_mass_over3500_engine_over150: null,
          buses_mass_over3500_engine_less150: null,
          buses_trucks_mass_less2000: null,
          buses_trucks_mass_over2000_less3500: null,
          road_trains_mass_over3500_engine_less75: null,
          road_trains_mass_over3500_engine_over75_less150: null,
          road_trains_mass_over3500_engine_over150: null
        },
        test_result: {
          cars_trucks: null,
          buses_mass_over3500_engine_over150: null,
          buses_mass_over3500_engine_less150: null,
          buses_trucks_mass_less2000: null,
          buses_trucks_mass_over2000_less3500: null,
          road_trains_mass_over3500_engine_less75: null,
          road_trains_mass_over3500_engine_over75_less150: null,
          road_trains_mass_over3500_engine_over150: null
        },
        conformity: {
          cars_trucks: '',
          buses_mass_over3500_engine_over150: '',
          buses_mass_over3500_engine_less150: '',
          buses_trucks_mass_less2000: '',
          buses_trucks_mass_over2000_less3500: '',
          road_trains_mass_over3500_engine_less75: '',
          road_trains_mass_over3500_engine_over75_less150: '',
          road_trains_mass_over3500_engine_over150: ''
        }
      },
      inspection: {
        post1: null,
        post2: null,
        initials_surname1: null,
        initials_surname2: null,
      },
      mismatch_codes: null,
      conclusion: '',

      isShowManufactureDate: false,
      isShowStateRegistrationDate: false,
      isShowDocsReequipmentDate: false,
      validationField,

      turbineCompressorArray: [
        { id: null, name: this.$t('protocol.noTurbineCompressor') },
        { id: 1, name: this.$t('protocol.turbine') },
        { id: 2, name: this.$t('protocol.compressor') }
      ]
    }
  },
  validations: validate.protocolResult,

  computed: {
    ...mapState({
      stand: state => state.stand
    })
  },

  mounted() {
    this.$store.commit('setDataTitle', 'Протокол результату перевірки')
  },

  methods: {
    ...mapActions(['getModel']),
    licensePlateUpper () {
      this.car_parameters.car_license_plate = this.car_parameters.car_license_plate.toUpperCase()
    }
  }
}
</script>

<style lang="scss">
.protocol-label {
  font-weight: bold;
  color: gray;
}
.default-input {
  .v-input__slot {
    border: none;
    background: transparent;
    border-radius: 0;
    padding: 0;
  }
  .v-label {
    font-weight: normal;
  }
}
.table-label {
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #8F9BB3;
  margin-top: 4px;
  padding-left: 4px;
}
.bb-1 {
  border-bottom: 1px solid #0000001f
}
.noWrap {
  white-space: nowrap;
}

</style>
